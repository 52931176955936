import React from 'react'
import { ThreeDCardPreview } from '@sendoutcards/editor'
import {
  Drawer,
  DrawerProps,
  Flex,
  Text,
} from '@sendoutcards/quantum-design-ui'
import { useRef } from 'react'
import { MinimalDigitalCardFragment } from 'src/graphql/generated/graphql'
import {
  mapPanelControlNames,
  mapPanelImages,
} from 'src/helpers/threeDCard/mapPanelImages'
import {
  useActions,
  useEffect,
  usePersistedUserData,
  useSelector,
  useState,
} from 'src/hooks'
import { DuvetV2 } from '../DuvetV2/DuvetV2'
import useSnapPoints from 'src/hooks/useSnapPoints'
import { RecipientView } from '../RecipientView/RecipientView'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import ActRequestCard from '../ActRequestCard/ActRequestCard'
import { useGetActRecipientActionScreenContent } from 'src/react_query'
import { ActSendFreeCardDialog } from '../FreeCardSendModal/ActSendFreeCardDialog'
import { useSocPublicImageBucketUrl } from 'src/hooks/useSocPublicImageBucketUrl'
import { UpsaleDialog } from 'src/upsale/components/UpsaleDialog'
type ViewActProps = {
  digitalCard: MinimalDigitalCardFragment
}

const ViewAct: React.FC<ViewActProps> = props => {
  const { digitalCard } = props
  const { account } = usePersistedUserData()
  const { data: recipientActionScreenContent } =
    useGetActRecipientActionScreenContent()
  const freeCardUpsaleContent =
    recipientActionScreenContent?.freeCardUpsaleContent
  const leftDrawerRef = useRef<HTMLDivElement>(null)
  const isDesktop = useSelector(state => state.window.width >= 961)
  const controlContainerRef = useRef<HTMLDivElement>(null)
  const [shouldPortalControls, setShouldPortalControls] = useState(false)
  const [shouldShowRequestCard, setShouldShowRequestCard] = useState(false)
  const [shouldShowFreeCardUpsale, setShouldShowFreeCardUpsale] =
    useState(false)
  const [shouldShowSendFreeCardFlow, setShouldShowSendFreeCardFlow] =
    useState(false)

  const controlPanelNames = mapPanelControlNames(digitalCard.card.panels)

  const actions = useActions()

  const { duvetState, isRecipientDrawerOpen } = useSelector(state => state.act)

  const { snapPoints, openValue, handleSnap } = useSnapPoints(
    'recipientView',
    duvetState,
  )

  const dummyRecipient = {
    title: digitalCard.shareMessageTitle,
    sender: {
      firstname: digitalCard.sponsor.firstName ?? '',
      lastname: digitalCard?.sponsor.lastName ?? '',
      imageUrl: digitalCard?.sponsor.storefront?.mugshot?.url,
    },
    primaryAction: {
      title: 'Try it yourself',
      onClick: () => setShouldShowFreeCardUpsale(true),
    },
    secondaryAction: {
      onClick: () => setShouldShowRequestCard(true),
    },
  }

  const renderDrawerContent = <RecipientView {...dummyRecipient} />

  useEffect(() => {
    if (controlContainerRef.current) {
      setShouldPortalControls(true)
    }
  }, [])

  const containerStyles = {
    height: isDesktop ? '100dvh' : 'calc(100dvh - 65px)',
    width: '100dvw',
    position: 'fixed',
    top: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#FEFEFE',
  } as const

  const drawerProps: DrawerProps = {
    isTabHidden: false,
    zIndex: 10,
    location: 'left',
    offset: 0,
    openValue: 350,
    isOpen: isRecipientDrawerOpen,
    onClick: () => actions.setRecipientDrawerOpen(!isRecipientDrawerOpen),
    overflow: 'hidden',
    tabMode: 'dark',
  }

  const freeCardHeroImage = useSocPublicImageBucketUrl('freecardhero.png')

  return (
    <div style={{ ...containerStyles }}>
      <style>
        {`
          .osano-cm-dialog {
            background-color: #fff;
            font-size: 12px;
            display: flex;
            flex-direction: row;
            color: #555;
            padding: 0.25rem 0.75rem;
            column-gap: 0.5rem;
            box-shadow: 0 4px 10px 0 #bdbaba57;
            overflow: hidden;
          }

          .osano-cm-buttons {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            column-gap: 0.25rem;
          }

          .osano-cm-dialog--type_bar .osano-cm-button, .osano-cm-button {
            flex: 1 1 auto;
            max-height: 64px;
            aspect-ratio: 1 / 1;
            border-radius: 12px;
            border-style: none;
          }
          `}
      </style>
      {isDesktop && (
        <Drawer {...drawerProps}>
          <div style={{ height: '100%' }} ref={leftDrawerRef}>
            <Flex
              outset={{ vertical: 'x4' }}
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
            >
              <Icon name="promptings_logo_full_gradient" size={200} />
              <Flex flexDirection="row" columnGap="x_25">
                <Text
                  content="Powered by"
                  type="body"
                  isItalic
                  lineHeight={1.5}
                  style={{ fontSize: '11px', color: '#6B7280' }}
                />
                <Icon name="soc_logo" size={120} color="#000" />
              </Flex>
            </Flex>
            <Flex
              width="100%"
              height="100%"
              alignItems="center"
              flexDirection="column"
              inset="x1"
            >
              {renderDrawerContent}
            </Flex>
          </div>
        </Drawer>
      )}
      {shouldPortalControls && (
        <Flex
          outset={{ top: 'x4' }}
          height="100%"
          justifyContent="center"
          alignItems="center"
        >
          <ThreeDCardPreview
            cardType={digitalCard.card.type}
            orientation={
              digitalCard.card.isHorizontal ? 'LANDSCAPE' : 'PORTRAIT'
            }
            panels={mapPanelImages(
              digitalCard.card.frontPreviewUrl,
              digitalCard.card.insidePreviewUrl,
              digitalCard.card.backPreviewUrl,
              digitalCard.card.flapPreviewUrl,
              !!digitalCard.card.flapPreviewUrl,
              digitalCard.card.isHorizontal,
            )}
            height="calc(100dvh - 350px)"
            control={{
              type: 'Portal',
              portalId: 'controls3DCard',
              panelNames: controlPanelNames,
              shouldOnlyShowDropdown: !isDesktop,
            }}
            bgGradientColor={{ shouldDisplayBg: true }}
          />
        </Flex>
      )}
      <div
        style={{
          marginTop: 'auto',
          marginBottom: '1rem',
          width: '100%',
          padding: '0 1rem',
          zIndex: 8,
          position: 'relative',
        }}
      >
        <div
          ref={controlContainerRef}
          id="controls3DCard"
          style={{
            margin: `0 auto ${isDesktop ? '2.5rem' : '7rem'}`,
            maxWidth: '500px',
          }}
        />
      </div>
      {!isDesktop && (
        <DuvetV2
          snapPoints={snapPoints}
          onSnap={handleSnap}
          onClose={() => {
            actions.setDuvetState('navigation')
          }}
          openValue={openValue}
          isOpen={true}
          shouldShowCloseButton={true}
        >
          {renderDrawerContent}
        </DuvetV2>
      )}
      {shouldShowRequestCard &&
        digitalCard.recipients.length > 0 &&
        digitalCard.recipients[0].contactRequest && (
          <ActRequestCard
            digitalCardToken={digitalCard.recipients[0].token}
            contactRequestToken={digitalCard.recipients[0].contactRequest.token}
            cardImgUrl={digitalCard.card.frontPreviewUrl}
            onBack={() => {
              setShouldShowRequestCard(false)
            }}
          />
        )}
      {shouldShowFreeCardUpsale && digitalCard && (
        <UpsaleDialog
          isOpen={shouldShowFreeCardUpsale}
          title={freeCardUpsaleContent?.title ?? 'marketing Paragraph title'}
          heroImage={freeCardHeroImage}
          description={
            freeCardUpsaleContent?.content ?? 'marketing paragraph description'
          }
          onClose={() => setShouldShowFreeCardUpsale(false)}
          primaryAction={{
            title:
              !!account && !account.isQualifiedForFirstFreeCard
                ? 'Send'
                : 'Send Free Card',
            onClick: () => {
              setShouldShowFreeCardUpsale(false)
              if (!!account) {
                actions.openCatalog(undefined, { digitalSendSelected: '' })
              } else {
                setShouldShowSendFreeCardFlow(true)
              }
            },
          }}
        />
      )}
      {shouldShowSendFreeCardFlow && (
        <ActSendFreeCardDialog
          isOpen={true}
          onClose={() => {
            setShouldShowSendFreeCardFlow(false)
          }}
          onFinished={() => {
            setShouldShowSendFreeCardFlow(false)
            actions.openCatalog(undefined, { digitalSendSelected: '' })
          }}
        />
      )}
    </div>
  )
}

export default ViewAct
