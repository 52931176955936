import { useMemo } from 'src/hooks'

type PlanDetail = {
  detail: string
  innerDetails?: PlanDetail[]
  isIncluded?: boolean
}

export type FeatureType = {
  feature: string
  subFeatures: string[] // Ensures subFeatures is always an array
  isIncluded: boolean
}

export const usePlanFeatures = (description?: { details?: PlanDetail[] }) => {
  return useMemo<FeatureType[]>(() => {
    return (
      description?.details?.map(detail => {
        return {
          feature: detail.detail,
          subFeatures: detail.innerDetails?.map(d => d.detail) || [], // Default to an empty array
          isIncluded: detail.isIncluded ?? true,
        }
      }) || []
    )
  }, [description?.details])
}
