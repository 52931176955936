import { Flex, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'

export type OptionType = {
  title: string
  price: string
  isMonthly: boolean
  optionId: string
  disabledReason?: 'current plan' | 'added to cart'
}

type SelectableOptionsType = {
  options: OptionType[]
  selectedOption?: string
  onSelectOption: (optionId: string) => void
  customGradient?: string[]
  customBgColor?: string
}

export const applyOpacity = (color: string, opacity: number): string => {
  if (!color) return color

  if (color.startsWith('#')) {
    const hex = color.replace('#', '')
    const r = parseInt(hex.substring(0, 2), 16)
    const g = parseInt(hex.substring(2, 4), 16)
    const b = parseInt(hex.substring(4, 6), 16)
    return `rgba(${r}, ${g}, ${b}, ${opacity})`
  }

  if (color.startsWith('rgba')) {
    return color.replace(/rgba?\(([^)]+)\)/, (_, p1) => {
      return `rgba(${p1.split(',').slice(0, 3).join(',')}, ${opacity})`
    })
  }

  return color
}

export const SelectableOptions: React.FC<SelectableOptionsType> = ({
  options,
  selectedOption,
  onSelectOption,
  customGradient,
  customBgColor,
}) => {
  return (
    <Flex flexDirection="column" rowGap="x1" outset={{ vertical: 'x2' }}>
      {options.map(option => {
        const isSelected = option.optionId === selectedOption
        return option.disabledReason ? (
          <Flex
            key={option.title}
            height="36px"
            cursor="pointer"
            alignItems="center"
            borderRadius="12px"
            justifyContent="space-between"
            inset={{ horizontal: '12px', vertical: '8px' }}
            backgroundColor={
              customGradient ? applyOpacity(customGradient[1], 0.2) : '#F0F0F0'
            }
          >
            <Text
              type={'title'}
              style={{
                fontSize: '12px',
                color: '#333',
                fontWeight: 500,
              }}
              content={`${
                option.disabledReason === 'current plan'
                  ? 'Current Plan'
                  : option.disabledReason === 'added to cart'
                    ? 'Added to Cart'
                    : 'disabled'
              }`}
            />
            <Text
              type={'title'}
              style={{
                fontSize: '12px',
                color: '#333',
                fontWeight: 500,
              }}
              content={option.price}
            />
          </Flex>
        ) : (
          <div
            key={option.title}
            style={{
              cursor: 'pointer',
              height: '36px',
              display: 'flex',
              alignItems: 'center',
              borderRadius: '12px',
              padding: '8px 12px',
              border: isSelected
                ? `2px solid ${customGradient ? customGradient[1] : '#46C9DF'}`
                : '2px solid #F0F0F0',
              backgroundColor: customBgColor ?? undefined,
            }}
            onClick={e => {
              e.stopPropagation()
              onSelectOption(option.optionId)
            }}
          >
            <Flex alignItems="center">
              <Flex
                justifyContent="center"
                alignItems="center"
                width="16px"
                height="16px"
                backgroundColor={
                  isSelected
                    ? customGradient
                      ? applyOpacity(customGradient[1], 0.2)
                      : '#46C9DF'
                    : '#fff'
                }
                borderRadius="50%"
                style={{
                  border: !isSelected ? '2px solid #F0F0F0' : undefined,
                  animation: 'pulse 0.6s infinite ease-in-out',
                }}
              >
                {isSelected && (
                  <Icon
                    size={10}
                    color={customGradient ? customGradient[0] : '#005AC4'}
                    name={'check'}
                  />
                )}
              </Flex>
              <Text
                whiteSpace="nowrap"
                outset={{ left: 'x1' }}
                type={'title'}
                lineHeight={1}
                style={{
                  fontSize: '12px',
                  color: '#333',
                  fontWeight: 500,
                }}
              >
                {option.title}
              </Text>
            </Flex>
            <Flex width="100%" justifyContent="end">
              <Text
                outset={{ left: 'x1' }}
                type="body"
                weight="bold"
                lineHeight={1}
                style={{ color: '#333', fontSize: '14px' }}
              >
                {option.price.slice(0, -2)}
              </Text>
              <Text
                style={{ color: '#333', fontSize: '10px' }}
                weight="bold"
                lineHeight={1}
                type={'footnote'}
              >
                .00
              </Text>
              {option.title !== 'One-time Purchase' && (
                <Text
                  lineHeight={1}
                  type={'footnote'}
                  style={{
                    color: '#ACACAC',
                    fontSize: '8px',
                    fontWeight: 500,
                  }}
                >
                  /mo
                </Text>
              )}
            </Flex>
          </div>
        )
      })}
    </Flex>
  )
}
