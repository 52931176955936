import React from 'react'
import { Flex, Spacer, Text } from '@sendoutcards/quantum-design-ui'
import { Button } from 'src/design_system/components/Button/Button'
import {
  CardFragment,
  Contact,
  DigitalCardOrder,
} from 'src/graphql/generated/graphql'
import { useSelector, useState } from 'src/hooks'
import {
  useDigitalCardOrderCreate,
  useDigitalCardRecipientCreate,
  useDigitalCardUpdate,
} from 'src/react_query'
import styles from './prepareAct.module.scss'
import ContactSearch from '../ContactSearch/ContactSearch'
import CardCostTag from './CardCostTag'
import DialogHeader from '../ActSetup/DialogHeader'
import ActCardGraphic from '../ActCardGraphic/ActCardGraphic'
import { Dialog } from 'src/design_system/molecules/dialog/Dialog'

type PrepareActProps = {
  card: CardFragment
  onBack: () => void
  onPreparationComplete: (digitalOrder: DigitalCardOrder) => void
}

const PrepareAct: React.FC<PrepareActProps> = props => {
  const { card, onBack, onPreparationComplete } = props

  const createDigitalCardRecipientMutation = useDigitalCardRecipientCreate()
  const updateDigitalCardMutation = useDigitalCardUpdate()
  const digitalCardOrderCreateMutation = useDigitalCardOrderCreate()
  const isMobile = useSelector(state => state.window.width < 799)

  const [title, setTitle] = useState(card.detailedSendableCard?.title ?? '')
  const [recipientName, setRecipientName] = useState('')
  const [contact, setContact] = useState<Contact | undefined>(undefined)

  const isRecipientSelected = !!recipientName || !!contact

  const isCreatingActOrder =
    updateDigitalCardMutation.isLoading ||
    createDigitalCardRecipientMutation.isLoading ||
    digitalCardOrderCreateMutation.isLoading

  const createActOrder = async () => {
    if (isRecipientSelected) {
      const digitalCardPk = card.digitalCard?.pk
      if (digitalCardPk) {
        await updateDigitalCardMutation.mutateAsync({
          digitalCardPk: digitalCardPk,
          input: { shareMessageTitle: title },
        })
        await createDigitalCardRecipientMutation.mutateAsync({
          digitalCardPk: digitalCardPk,
          ...(contact
            ? { contactUuid: contact.id }
            : { firstName: recipientName }),
        })
        const digitalOrder = await digitalCardOrderCreateMutation.mutateAsync({
          digitalCardPk: digitalCardPk,
        })

        onPreparationComplete(digitalOrder)
      }
    }
  }

  const handleContactSelect = (selectedContact?: Contact) => {
    setContact(selectedContact)
  }

  const handleContactSearchRecipientChanges = (value: string) => {
    setRecipientName(value)
    if (contact) {
      setContact(undefined)
    }
  }

  const goToContactSearch = () => {
    if (document) {
      const input = document.querySelector('#data_input_cs') as HTMLInputElement
      input?.focus()
    }
  }
  const actionButtonSharedStyles: React.CSSProperties = {
    marginTop: 'auto',
    marginBottom: '1rem',
    width: '100%',
    padding: '0 1rem',
    zIndex: 10,
    position: 'relative', // Correctly typed
  }

  const mobileActionStyles: React.CSSProperties = {
    position: 'fixed', // Correctly typed
    bottom: 0,
    left: 0,
  }

  const actionContainerStyles: React.CSSProperties = isMobile
    ? { ...actionButtonSharedStyles, ...mobileActionStyles }
    : actionButtonSharedStyles

  const mobileFixedActionContainerHeight = isMobile ? '100px' : 'initial'

  return (
    <Dialog
      mobileBreakPointOverride={799}
      isOpen={true}
      onClose={onBack}
      padding={isMobile ? '20px 10px 40px' : undefined}
      outerWrapperStyle={{
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: mobileFixedActionContainerHeight,
      }}
      wrapperStyle={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: '1',
        overflowX: 'auto',
      }}
      shouldHideTitleBar={true}
      mobileOpenHeight="0px"
    >
      <DialogHeader onBack={onBack} />
      {isMobile && (
        <div
          style={{
            width: '100%',
            marginTop: '24px',
            marginBottom: '24px',
          }}
        >
          <CardCostTag
            cardCost={undefined}
            title={card.detailedSendableCard?.title}
            cardPreviewUrl={card.frontPreviewUrl}
          />
        </div>
      )}
      <div
        style={{
          display: 'flex',
          columnGap: !isMobile ? '3rem' : undefined,
          marginTop: !isMobile ? '2rem' : undefined,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
          }}
        >
          {!isMobile && (
            <>
              <Spacer space="x2" />
              <ActCardGraphic
                isHorizontal={card.isHorizontal ?? false}
                cardPreviewUrl={card.frontPreviewUrl}
                shouldShowGradientBackground={false}
                shouldShowCellphone={true}
                shouldShowEnvelope={false}
              />
            </>
          )}
        </div>
        <div style={{ minWidth: '365px' }}>
          <div
            style={{
              width: '100%',
              padding: '0.5rem 1rem 0',
              marginTop: '0.5rem',
            }}
          >
            <Text
              type="caption"
              content="Who do you want to send this to?"
              alignment="center"
              style={{ fontSize: '16px', fontWeight: 700 }}
            />
            <div>
              <ContactSearch
                onContactSelect={handleContactSelect}
                onChange={handleContactSearchRecipientChanges}
                currentlySelectedContactId={contact?.id}
              />
            </div>
            <div>
              <Flex width="100%" flexDirection="column" rowGap={'x1'}>
                <Text
                  type="caption"
                  content="Subject"
                  weight="semiBold"
                  outset={{ top: 'x2' }}
                />
                <input
                  type="text"
                  className={styles.input}
                  value={title}
                  onChange={e => setTitle(e.target.value)}
                  autoCorrect="off"
                  spellCheck="false"
                  autoComplete="off"
                />
                <Spacer space="x1" />
              </Flex>
            </div>
            <div style={actionContainerStyles}>
              <div id="controls3DCard" style={{ marginBottom: '2rem' }} />
              <Button
                title={
                  isCreatingActOrder
                    ? 'Creating Order...'
                    : isRecipientSelected
                      ? 'Save & Share'
                      : 'Add a Recipient'
                }
                onClick={
                  isRecipientSelected ? createActOrder : goToContactSearch
                }
                width="100%"
                height="64px"
                borderRadius="1rem"
                backgroundColor={isRecipientSelected ? '#404040' : 'gray'}
                isDisabled={isCreatingActOrder}
              />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default PrepareAct
