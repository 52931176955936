import React from 'react'
// @src imports
import { DefaultError, Transition } from 'src/chrome'
import { SendType } from 'src/catalog/components/CardOptions/CardOptions'
// relative imports
import {
  getRouteCardType,
  getRoutePaperType,
} from '../../../redux/selectors/catalog'
import {
  useActions,
  useEffect,
  useFlag,
  usePersistedUserData,
  useRoute,
  useSelector,
  useState,
} from 'src/hooks'
import * as Result from 'src/utils/Result'
import suspenseBoundary from 'src/chrome/SuspenseBoundary/suspenseBoundaryHOC'
import { CardPreviewFlow } from 'src/act/components/CardPreview/CardPreview'
import {
  useCreateCard,
  useDigitalCardCreate,
  useFlags,
  useGetCard,
  useGetSendableCard,
} from 'src/react_query'
import {
  CardFragment,
  CardPaperType,
  CardType,
  CreateCardMutationVariables,
  DetailedSendableCardFragment,
} from 'src/graphql/generated/graphql'
import { CustomCardEditRoute } from 'src/card/routes/CustomCardEditRoute'
import NewEditorOptionModal from 'src/chrome/NewEditorOptionModal/NewEditorOptionModal'
import { CampaignsRoute } from 'src/campaigns/routes/CampaignsRoute'
import { OrdersRoute } from 'src/orders/routes/OrdersRoute'

export const DIGITAL_CARD_TYPES = [
  CardType.Flatcard,
  CardType.TwoPanel,
  CardType.ThreePanel,
]

interface Props {
  onClose: () => void
  onSelect: (id: string) => void
  type: 'SendableCard' | 'Card'
  id: string
}

const CardPreviewModal: React.FC<Props> = props => {
  const { onClose, onSelect, type, id: cardId } = props

  const actions = useActions()
  const route = useRoute()
  const { account } = usePersistedUserData()
  const routeCardType = useSelector(getRouteCardType)
  const routePaperType = useSelector(getRoutePaperType)

  const { data: flags } = useFlags()
  const createCardMutation = useCreateCard()
  const createDigitalCardMutation = useDigitalCardCreate()

  const { newCardEditor: canShowNewCardEditor, actDisplay: hasActDisplay } =
    useFlag(flags ?? [])

  const getSendableCardQuery = useGetSendableCard(
    {
      id: type === 'SendableCard' ? cardId : undefined,
    },
    { suspense: true },
  )
  const getCardQuery = useGetCard(
    {
      id: type !== 'SendableCard' ? cardId : undefined,
    },
    { suspense: true },
  )
  const sendableCard: DetailedSendableCardFragment | undefined =
    getSendableCardQuery.data?.sendableCard
  const customCard: CardFragment | undefined = getCardQuery.data?.card
  const card = type === 'SendableCard' ? sendableCard : customCard

  const isOrderOrCampaignRoute =
    route.subroute.path === CampaignsRoute.path ||
    route.subroute.path === OrdersRoute.path

  const shouldShowAct =
    hasActDisplay &&
    card !== undefined &&
    // Should show only if custom card has num of panels supported by ACT
    ((card.__typename === 'Card' && DIGITAL_CARD_TYPES.includes(card.type)) ||
      card.__typename === 'SendableCard') &&
    // Shouldn't show for Orders and Campaigns
    !isOrderOrCampaignRoute

  const isDigitalSendSelected = 'digitalSendSelected' in route.args

  const [sendType, setSendType] = useState<SendType>(
    isDigitalSendSelected ? SendType.Digital : SendType.Print,
  )
  const [variationId, setVariationId] = useState<string | undefined>(undefined)
  const [cardType, setCardType] = useState(routeCardType ?? CardType.TwoPanel)
  const [paperType, setPaperType] = useState(
    routePaperType ?? CardPaperType.Std,
  )
  const [shouldShowEditorChoiceModal, setShouldShowEditorChoiceModal] =
    useState<boolean>(false)

  const handleSetSendType = (value: SendType) => {
    setSendType(value)
    if (value === SendType.Digital && !DIGITAL_CARD_TYPES.includes(cardType)) {
      handleSetCardType(CardType.TwoPanel)
      handleSetPaperType(
        // It shouldn't matter for DigitalCards
        CardPaperType.Std,
      )
    }
  }

  const handleSetCardType = (value: CardType) => setCardType(value)

  const handleSetPaperType = (value: CardPaperType) => setPaperType(value)

  const createCard = async ({
    card,
    sendableCard,
    type,
    paperType,
    isNewEditorCard,
  }: CreateCardMutationVariables) => {
    try {
      const { createCard } = await createCardMutation.mutateAsync({
        card,
        sendableCard,
        type: isFreeSend ? CardType.TwoPanel : type,
        paperType: isFreeSend ? CardPaperType.Std : paperType,
        isNewEditorCard,
      })

      if (sendType === SendType.Digital && createCard.card.id) {
        await createDigitalCardMutation.mutateAsync({
          cardId: createCard.card.id,
          input: {},
        })
      }

      actions.loadedUser(Result.success(createCard.account))
      onSelect(createCard.card.id)
    } catch (error) {
      console.error(error)
    }
  }

  const handleEditCard = async (
    id: string,
    cardType: CardType,
    paperType: CardPaperType,
    isNewEditorCard: boolean = false,
  ) => {
    await createCard({
      sendableCard: id,
      type: cardType,
      paperType,
      isNewEditorCard,
    })
  }

  const handleEditCustomCard = () =>
    card && actions.openCard(card.id, CustomCardEditRoute())

  const handleSendCustomCard = async (isNewEditorCard: boolean = false) => {
    await createCard({ card: cardId, isNewEditorCard })
  }

  const handleSelectEditor = (selectedNewEditor: boolean) => {
    const id = variationId ?? card?.id
    if (id === cardId && type !== 'SendableCard') {
      handleSendCustomCard(selectedNewEditor)
    } else if (id && type === 'SendableCard') {
      handleEditCard(id, cardType, paperType, selectedNewEditor)
    }
    setShouldShowEditorChoiceModal(false)
  }

  const onComplete = () => {
    if (canShowNewCardEditor) {
      if (
        (shouldShowAct && sendType === SendType.Digital) ||
        (card && card.__typename === 'Card' && card.isNewEditorCard)
      ) {
        handleSelectEditor(true)
      } else {
        setShouldShowEditorChoiceModal(true)
      }
    } else {
      const id = variationId ?? card?.id
      if (id) {
        handleEditCard(id, cardType, paperType, false)
      }
    }
  }

  useEffect(() => {
    if (cardType === CardType.Postcard && paperType !== CardPaperType.Std) {
      handleSetPaperType(
        // Postcards may only have standard paper type
        CardPaperType.Std,
      )
    }
  }, [cardType, paperType])

  const isFreeSend = account?.isQualifiedForFirstFreeCard ?? false

  if (!card) {
    throw new Error('Missing card')
  }

  if (canShowNewCardEditor && shouldShowEditorChoiceModal) {
    return (
      <NewEditorOptionModal
        title={'Your Choice! Where Would You Like to Create Your Card?'}
        description={
          'As a selected user, you get early access to our enhanced card-sending experience—now available on desktop and mobile web!  Choose how you would like to continue.'
        }
        tryNewEditor={{
          title: 'Try Enhanced Experience',
          onOverrideClick: () => handleSelectEditor(true),
        }}
        useCurrentEditor={{
          title: 'Use Current Experience',
          onClick: () => handleSelectEditor(false),
        }}
        isOpen={shouldShowEditorChoiceModal}
        onClose={() => {
          setShouldShowEditorChoiceModal(false)
        }}
      />
    )
  }

  return createCardMutation.isLoading ? (
    <Transition />
  ) : (
    <CardPreviewFlow
      initialStep="SelectSendType"
      shouldShowAct={shouldShowAct}
      card={card}
      isFreeSend={isFreeSend}
      variationId={variationId}
      onChangeVariationId={setVariationId}
      sendType={sendType}
      onChangeSendType={handleSetSendType}
      selectedCardType={cardType}
      onCardTypeSelect={(cardType: CardType) => {
        handleSetCardType(cardType)
        console.log(cardType, 'my new card type')
      }}
      selectedPaperType={paperType}
      onPaperTypeSelect={(paperType: CardPaperType) => {
        handleSetPaperType(paperType)
        console.log(paperType, 'papertype')
      }}
      onEditCustomCard={handleEditCustomCard}
      onComplete={onComplete}
      onClose={onClose}
    />
  )
}

export default suspenseBoundary({
  component: CardPreviewModal,
  unresolved: (
    <Transition
      message={'Loading your selection...'}
      messageStyle={{ color: 'black', fontWeight: 500 }}
    />
  ),
  failure: DefaultError,
})
