import { DetailedCountryFragment } from 'src/legacy_graphql'
import { CountryCode } from 'src/saleor_graphql/generated/graphql'

const getCountryCodeFromPostalName = (
  postalName: string,
  detailedCountries: DetailedCountryFragment[],
): CountryCode | undefined => {
  const countryCode = detailedCountries?.find(
    country => country.postalName === postalName,
  )?.alpha2 as CountryCode | undefined
  return countryCode
}

export default getCountryCodeFromPostalName
