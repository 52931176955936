import React from 'react'
import { useGetDigitalCardByToken } from 'src/react_query'
import ViewAct from '../components/ViewAct/ViewAct'
import { ActRoute } from '../routes/ActRoute'
import { useQueryParams } from 'src/hooks'
import { LoadingSpinner } from '@sendoutcards/quantum-design-ui'
import ActSetup from '../components/ActSetup/ActSetup'

type ActProps = {
  route: ActRoute
}

const Act: React.FC<ActProps> = ({ route }) => {
  const { token } = useQueryParams()
  const { data, isFetching } = useGetDigitalCardByToken({ token: token ?? '' })

  const loadScreenSyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100dvh',
  }

  if (isFetching) {
    return (
      <div style={{ ...loadScreenSyles }}>
        <LoadingSpinner size="large" />
      </div>
    )
  }

  if (!route.subroute) {
    return data ? (
      <ViewAct digitalCard={data} />
    ) : (
      <div style={{ ...loadScreenSyles }}>
        <LoadingSpinner size="large" />
      </div>
    )
  }

  return <ActSetup route={route} cardId={route.subroute.cardId} />
}

export default Act
