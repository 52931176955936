import React from 'react'
import moment from 'moment'

// @src imports
import {
  useEffect,
  useMemo,
  usePersistedState,
  useQueries,
  useSelector,
  useSubscriptions,
} from 'src/hooks'

import useActions from 'src/hooks/useActions'
import { Category as ItemCategory, Item } from 'src/campaign_store/types'
import { ACCOUNT_TOOLS_ID } from 'src/app/constants'

import {
  AffiliatePaymentInfo,
  ItemsPaymentInfo,
  PurchaseTypes,
} from 'src/payments/containers/Payment'

import { Confirm, DefaultError, NavLink, Transition } from 'src/chrome'

import { PricingRoute } from 'src/marketing/plans/routes/PricingRoute'

import { getItemCategory, getPlansDescriptions } from 'src/legacy_graphql'

import { noop } from 'src/helpers/appHelpers'

// relative imports
import { PlansDescription } from '../types'

import {
  AccountStorage,
  AutomatedGSendsAndCampaignGrid,
  ContentSplitter,
  PlanContentsGrid,
  RoundedFooter,
} from '../components'

import suspenseBoundary from 'src/chrome/SuspenseBoundary/suspenseBoundaryHOC'
import WaysToSend from '../components/WaysToSend/WaysToSend'
import CampaignStoreAddOn from '../components/CampaignStoreAddOn/CampaignStoreAddOn'
import Plans from 'src/plans/containers/Plans'
import PlanAddons from 'src/plans/containers/PlanAddons'
import {
  Button,
  Dialog,
  Div,
  Flex,
  Image,
  Separator,
  Spacer,
} from '@sendoutcards/quantum-design-ui'
import BasicPlan from '../components/BasicPlan/BasicPlan'
import PromoItem from '../components/pricing_table/PromoItem'
import getLocalStorage from 'src/helpers/getLocalStorage'
import { usePlanAddons } from 'src/hooks/usePlanAddons'
import { useUpdateAccount } from 'src/react_query'
import { itemCategoryFromLegacy } from 'src/graphql/compat'
import { useFormatSOCMediaUrl } from 'src/hooks/useFormatSOCMediaUrl'
import { PromptingsPackageUpsale } from 'src/chrome/PromptingsPackageUpsale/PromptingsPackageUpsale'

const styles = {
  pricingContainer: {
    width: '100%',
    fontFamily: "'Open Sans', sans-serif",
  },
}

type PlansProps = {
  route?: PricingRoute
}

type Dialog =
  | PlanUpsaleDialog
  | PaymentModal
  | CancelConfirmation
  | KeepSubscription

interface PlanUpsaleDialog {
  kind: 'PlanUpsale'
  selectedPlanId: string
  isAddon: boolean
}

interface PaymentModal {
  kind: 'PaymentModal'
  paymentInfo: ItemsPaymentInfo | AffiliatePaymentInfo
}

interface CancelConfirmation {
  kind: 'CancelConfirmation'
  selectedPlanId: string
  downgrade: string
}

interface KeepSubscription {
  kind: 'KeepSubscription'
  selectedPlanId: string
}

type State = {
  accountTools?: ItemCategory | null
  activeItem?: Item | null
  upgradeConfirm: boolean
  dialog?: Dialog
  isCanceling: boolean
  selectedItem: Item | null
  purchasing?: boolean
  next: string | null
}

const initialState: State = {
  upgradeConfirm: false,
  dialog: undefined,
  isCanceling: false,
  selectedItem: null,
  purchasing: false,
  next: null,
}
export const discountDisclaimer: string[] = [
  '* Discounts apply to most gifts. Excludes: books, apparel, and business-building tools.',
  '** A Heartfelt Prompting Card has an immediate send date with only one recipient attached.',
  '*** A System Card has a future send date or more than one recipient.',
]

const AnnouncementBanners = React.lazy(
  async () => import('src/marketing/AnnouncementBanners/AnnouncementBanners'),
)

const PlanUpsale = React.lazy(async () => {
  const module = await import('src/plan_upsale/PlanUpsale')
  return { default: module.PlanUpsale }
})

const PaymentModal = React.lazy(
  async () => import('src/payments/components/PaymentModal'),
)

const Pricing: React.FC<PlansProps> = props => {
  const [state, _setState] = usePersistedState('join-page', initialState)
  const { isMobile } = useSelector(state => state.window)

  const setState = (updates: Partial<State>) =>
    _setState(state => ({ ...(state ?? initialState), ...updates }))

  const resetState = () =>
    _setState(state => ({
      ...(state ?? initialState).accountTools,
      ...initialState,
    }))

  const { accountTools, activeItem, dialog, next } = state

  const { route } = props

  const actions = useActions()
  const storage = getLocalStorage()

  const updateAccountMutation = useUpdateAccount()
  const { promotionalAddons, baseAddons } = usePlanAddons()

  const [plansDescriptionFragments, itemCategory] = useQueries(
    getPlansDescriptions(),
    getItemCategory({ id: ACCOUNT_TOOLS_ID }),
  )

  const { currentPlan, currentSubscription } = useSubscriptions()
  const url = useFormatSOCMediaUrl('your-smile-2')
  const plansDescriptions = useMemo(
    () =>
      plansDescriptionFragments
        .map(PlansDescription.fromPlansDescriptionFragment)
        .filter(value => !value.description.isPromotional),
    [plansDescriptionFragments],
  )

  const currentPlanDescription = plansDescriptions.find(
    d => d.id === currentPlan.id,
  )

  const closeCancel = () => resetState()

  const confirmCancel = async (selectedPlanId: string) => {
    setState({
      dialog: undefined,
      isCanceling: true,
    })

    try {
      const {
        updateAccount: { account },
      } = await updateAccountMutation.mutateAsync({
        account: {
          plan: selectedPlanId,
        },
      })
      actions.updatedAccount?.(account)
      resetState()
    } catch (err) {
      handlePurchaseError()
    }
  }

  const showPlanUpgrade = (planId: string, isKeepButton?: boolean) => {
    if (isKeepButton) {
      setState({
        dialog: {
          kind: 'KeepSubscription',
          selectedPlanId: planId,
        },
      })
      return
    }

    const newPlanDescription = plansDescriptions.find(d => d.id === planId)
    const isDowngrading =
      (newPlanDescription?.description.order ?? 0) <
      (currentPlanDescription?.description.order ?? 0)

    if (isDowngrading) {
      setState({
        dialog: {
          kind: 'CancelConfirmation',
          selectedPlanId: planId,
          downgrade: newPlanDescription?.description.plan ?? 'Unknown',
        },
      })
    } else {
      setState({
        dialog: {
          kind: 'PlanUpsale',
          selectedPlanId: planId,
          isAddon: false,
        },
      })
    }
  }

  const showPlanAddonBuy = (planAddonId: string) => {
    setState({
      dialog: {
        kind: 'PlanUpsale',
        selectedPlanId: planAddonId,
        isAddon: true,
      },
    })
  }

  const loadAccountTools = () => {
    setState({
      accountTools: {
        ...itemCategoryFromLegacy(itemCategory),
        children: itemCategory.children.map(child => ({
          ...itemCategoryFromLegacy(child),
          children: [],
        })),
      },
    })
  }

  const purchase = (item: Item) => {
    setState({
      activeItem: null,
      dialog: {
        kind: 'PaymentModal',
        paymentInfo: {
          items: [item],
          purchaseType: PurchaseTypes.ITEMS,
        },
      },
    })
  }

  const handlePurchaseSuccess = () => {
    if (next) {
      window.location.reload()
    }
  }

  const handlePurchaseError = () => resetState()

  const showProductPreview = (item: Item) =>
    setState({
      activeItem: item,
    })

  const closeProductPreview = () => setState({ activeItem: null })

  useEffect(() => {
    loadAccountTools()
    return () => {
      storage?.removeItem('join-page')
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  return (
    <Div>
      {route?.path === PricingRoute.path && (
        <Div>
          <AnnouncementBanners />
        </Div>
      )}
      <PaymentModal
        isOpen={dialog?.kind === 'PaymentModal'}
        paymentInfo={
          dialog?.kind === 'PaymentModal' ? dialog?.paymentInfo : undefined
        }
        onClose={() => resetState()}
        onSuccess={
          dialog?.kind === 'PaymentModal' &&
          dialog?.paymentInfo?.purchaseType === PurchaseTypes.DISTRIBUTOR
            ? handlePurchaseSuccess
            : noop
        }
        onError={
          dialog?.kind === 'PaymentModal' &&
          dialog?.paymentInfo?.purchaseType === PurchaseTypes.DISTRIBUTOR
            ? handlePurchaseError
            : noop
        }
      />
      <Div css={styles.pricingContainer}>
        <Plans onSelect={showPlanUpgrade} />
        {promotionalAddons && (
          <Flex
            width="100%"
            flexDirection="column"
            inset={{ vertical: 'x3', horizontal: 'x7' }}
          >
            <Separator caption="Or" orientation="horizontal" />
            {promotionalAddons.map(addon => {
              const handleAction = () => showPlanAddonBuy(addon.id)
              return (
                <PromoItem
                  key={addon.id}
                  title="Promotions"
                  onClick={handleAction}
                  item={{
                    isStacked: isMobile,
                    theme: 'dark',
                    tag: {
                      title: 'Challenge',
                      icon: 'heartfelt',
                      iconColor: '#cad961',
                    },
                    title:
                      addon.description.secondaryTitle ??
                      addon.description.plan,
                    caption: addon.description.description,
                    graphicBackground: {
                      color: '#FFFFFF',
                      image: '',
                    },
                    graphicPadding: '0px',
                    graphic: (
                      <Image
                        borderRadius="none"
                        image={{
                          url,
                        }}
                        width={'100%'}
                        height="300px"
                        isActive={true}
                      />
                    ),
                    actions: (
                      <Button
                        title="Claim Offer"
                        backgroundColorOverride="#cad961"
                        onClick={handleAction}
                      />
                    ),
                  }}
                />
              )
            })}
          </Flex>
        )}

        <RoundedFooter
          isOpen={false}
          messages={discountDisclaimer}
          actionTitle={'View Comparison'}
          onClick={() => {}}
          isChartAvailable={false}
        />
        <PromptingsPackageUpsale />
        <PlanAddons
          onSelect={showPlanAddonBuy}
          planAddonDescriptions={baseAddons}
        />
        <BasicPlan onSelect={showPlanUpgrade} />
        <WaysToSend />
        <PlanContentsGrid />
        <AutomatedGSendsAndCampaignGrid />
        <AccountStorage />
        <CampaignStoreAddOn
          accountTools={accountTools}
          activeItem={activeItem}
          purchase={purchase}
          showProductPreview={showProductPreview}
          closeProductPreview={closeProductPreview}
        />
        <>
          <NavLink onClick={() => actions.openCampaignStore()}>
            <ContentSplitter
              title={'View Campaign Store'}
              description={
                'We have campaigns for just about every niche you can think of.'
              }
              icon={{
                icon: 'CART',
                size: 30,
                viewBox: '0 0 35 32',
                color: '#fff',
              }}
            />
          </NavLink>
          <Spacer space="x8" />
        </>
        {dialog?.kind === 'CancelConfirmation' && (
          <Confirm
            title={`Downgrade ${
              currentPlanDescription?.description.plan ?? 'Unknown'
            } Subscription`}
            message={`
              Your subscription will be downgraded to ${
                dialog.downgrade
              } at the end of the current billing period:
              ${
                currentSubscription &&
                moment(currentSubscription.currentPeriodEnd).format(
                  'ddd MMM DD, YYYY HH:mm a',
                )
              }
            `}
            confirmTitle={'Continue'}
            onConfirm={() => confirmCancel(dialog.selectedPlanId)}
            onDecline={closeCancel}
            isModalEnabled={true}
          />
        )}
        {dialog?.kind === 'KeepSubscription' && (
          <Confirm
            title={`Keep current subscription`}
            message={`
              Do not change my subscription. I want to keep my current subscription
            `}
            confirmTitle={'Confirm'}
            onConfirm={() => confirmCancel(dialog.selectedPlanId)}
            onDecline={closeCancel}
            isModalEnabled={true}
          />
        )}
        {dialog?.kind === 'PlanUpsale' && (
          <PlanUpsale
            selectedPlanId={dialog.selectedPlanId}
            onClose={() => {
              resetState()
            }}
            isAddon={dialog.isAddon}
          />
        )}
      </Div>
    </Div>
  )
}

export default suspenseBoundary({
  component: Pricing,
  unresolved: <Transition />,
  failure: DefaultError,
})
