import { PlanFragment } from 'src/graphql/generated/graphql'
import { useMemo } from 'src/hooks'
import { usePlans } from 'src/react_query'
import { getPlanDescriptions } from './getPlanDescriptions'

type PlanIdType = {
  id: string
}

export const usePlanManagement = (planIds: PlanIdType[]) => {
  const plansQuery = usePlans({ suspense: true })
  const isFetching = plansQuery.isFetching
  const plans = plansQuery.data!

  const planDescriptions = useMemo(() => getPlanDescriptions(plans), [plans])

  const activePlans = useMemo(() => {
    return planIds
      .map(id => plans?.find(plan => id.id === plan.id))
      .filter((plan): plan is PlanFragment => !!plan)
  }, [plans, planIds])

  return { plans, planDescriptions, activePlans, isFetching }
}
